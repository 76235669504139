<template>
  <div class="px-3 py-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/logo/logo.png" alt="logo" width="80" />
        <span class="title-logo"> Smartworks </span>
      </div>

      <b-link
        :to="{ name: 'performance/goal' }"
        class="d-flex align-items-center bg-white p-1 rounded-pill cursor-pointer"
      >
        <feather-icon icon="ArrowLeftIcon" />
        <span class="bold ml-1">Back</span>
      </b-link>
    </div>

    <div class="text-center mt-3">
      <h2 style="color: #1e1e1e">Summary</h2>
      <span class="text-muted">
        Please review your goals before submitting
      </span>
    </div>

    <b-row class="justify-content-center">
      <b-col md="8">
        <b-card class="mt-3 rounded-3">
          <swiper
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide v-for="(data, index) in datas" :key="index">
              <b-card :title="'# Goals ' + (index + 1)">
                <b-card-body>
                  <div class="mb-1">
                    <label style="font-size: 13px; font-weight: 550">
                      Strategic Target
                    </label>
                    <p style="font-size: 13px">{{ data.strategic_goals }}</p>
                  </div>
                  <div class="mb-1">
                    <label style="font-size: 13px; font-weight: 550">
                      Key Performance Indicator
                    </label>
                    <p style="font-size: 13px">
                      {{ data.key_performance_indicator }}
                    </p>
                  </div>
                  <div class="mb-1">
                    <label style="font-size: 13px; font-weight: 550">
                      Weight
                    </label>
                    <p style="font-size: 13px">
                      {{ data.weight }}
                    </p>
                  </div>
                  <div class="mb-1">
                    <label style="font-size: 13px; font-weight: 550">
                      Target
                    </label>
                    <p style="font-size: 13px">
                      {{ data.target }}
                    </p>
                  </div>
                  <div class="mb-1">
                    <label style="font-size: 13px; font-weight: 550">
                      Due Date
                    </label>
                    <p style="font-size: 13px">
                      {{ data.due_date }}
                    </p>
                  </div>
                  <div class="mb-1" v-if="data.realization">
                    <label style="font-size: 13px; font-weight: 550">
                      Midyear Realization
                    </label>
                    <p style="font-size: 13px">
                      {{ data.realization }}
                    </p>
                  </div>
                  <div class="mb-1" v-if="data.final_realization">
                    <label style="font-size: 13px; font-weight: 550">
                      Final Realization
                    </label>
                    <p style="font-size: 13px">
                      {{ data.final_realization }}
                    </p>
                  </div>
                  <div class="mb-1" v-if="data.final_goal_status">
                    <label style="font-size: 13px; font-weight: 550">
                      Final Status
                    </label>
                    <p style="font-size: 13px">
                      {{ data.final_goal_status }}
                    </p>
                  </div>
                  <div class="mb-1" v-if="data.final_employee_score">
                    <label style="font-size: 13px; font-weight: 550">
                      Final Score
                    </label>
                    <p style="font-size: 13px">
                      {{
                        data.final_employee_score == 0
                          ? "Has not met Objectives"
                          : data.final_employee_score == 1
                          ? "Met Some Objectives but not All"
                          : data.final_employee_score == 2
                          ? "Met Objectives"
                          : data.final_employee_score == 3
                          ? "Exceed Some Objectives & Met Others"
                          : "Exceed All Objectives"
                      }}
                    </p>
                  </div>
                  <div class="mb-1" v-if="data.note">
                    <label style="font-size: 13px; font-weight: 550">
                      Note
                    </label>
                    <p style="font-size: 13px">
                      {{ data.note }}
                    </p>
                  </div>
                </b-card-body>
              </b-card>
            </swiper-slide>

            <swiper-slide v-if="datas[0].overAllScore">
              <b-card title="Over All Evaluation">
                <b-card-body>
                  <div class="mb-1">
                    <label style="font-size: 13px; font-weight: 550">
                      Over All Score
                    </label>
                    <p style="font-size: 13px">
                      {{
                        datas[0].overAllScore == 0
                          ? "Has not met Objectives"
                          : datas[0].overAllScore == 1
                          ? "Met some Objectives but not all"
                          : datas[0].overAllScore == 2
                          ? "Met Objectives"
                          : datas[0].overAllScore == 3
                          ? "Exceeded some Objectives & met others"
                          : "Exceeded all Objectives"
                      }}
                    </p>
                  </div>
                  <div class="mb-1">
                    <label style="font-size: 13px; font-weight: 550">
                      Over All Comment
                    </label>
                    <p style="font-size: 13px">{{ datas[0].overAllComment }}</p>
                  </div>
                </b-card-body>
              </b-card>
            </swiper-slide>
            <!-- Add Arrows -->
            <div slot="button-next" class="swiper-button-next" />
            <div slot="button-prev" class="swiper-button-prev" />
            <div slot="pagination" class="swiper-pagination" />
          </swiper>

          <b-card-footer>
            <div class="text-right">
              <b-link :to="{ name: 'performance/goal' }">
                <b-button
                  variant="outline-primary"
                  class="rounded-3 mr-1"
                  size="sm"
                  @click="history_back()"
                >
                  Back
                </b-button>
              </b-link>
              <b-button
                type="submit"
                variant="primary"
                class="rounded-3"
                size="sm"
                @click="submit_goals($event)"
              >
                Submit
              </b-button>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.title-logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: #423ffd;
  margin-left: 0.5rem;
}

.bg-white {
  background-color: #fff;
}

.rounded-3 {
  border-radius: 1.75rem;
}
</style>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          // type: "progressbar",
        },
      },

      datas: [],
      pushData: [],
      rtype: "",
      endPoint: "",
    };
  },
  mounted() {
    this.rtype = localStorage.getItem("rtype");

    if (this.rtype == "new_final" || this.rtype == "final") {
      let goals = JSON.parse(localStorage.getItem("goals"));
      goals[0].other.map((other, index) => {
        this.datas.push({
          id: other.id,
          strategic_goals: other.strategic_goals,
          key_performance_indicator: other.key_performance_indicator,
          weight: other.weight,
          target: other.target,
          due_date: other.due_date,
          realization: other.realization,
          final_realization: other.final_realization,
          final_goal_status: other.final_goal_status,
          final_employee_score: other.final_score,
          final_employee_behavior: other.behavior,
          final_manager_score: other.final_manager_score,
          final_manager_behavior: other.final_manager_behavior,
          final_manager_comment: other.final_manager_comment,
          overAllScore: other.overAllScore,
          overAllComment: other.overAllComment,
        });
        this.pushData.push({
          user_id: goals[0].item.user_id,
          goal_id: goals[index].item.id,
          midyear_id: goals[index].item.midyear_evaluation.id,
          final_realization: this.datas[index].final_realization,
          final_goal_status:
            this.datas[index].final_goal_status == "incomplete" ? 0 : 1,
          final_employee_score: this.datas[index].final_employee_score,
          final_employee_behavior: this.datas[index].final_employee_behavior,
          status: "new",
        });
      });
    } else if (this.rtype == "new_goals") {
      let goals = JSON.parse(localStorage.getItem("goals"));
      goals.map((other, index) => {
        this.datas.push({
          user_id: other.user_id,
          strategic_goals: other.strategic_goals,
          key_performance_indicator: other.key_performance_indicator,
          weight: other.weight,
          target: other.target,
          due_date: other.due_date,
          status: other.status,
        });
      });
    } else if (this.rtype == "new_midyear") {
      let goals = JSON.parse(localStorage.getItem("goals"));
      goals[0].other.map((other, index) => {
        this.datas.push({
          user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          strategic_goals: other.strategic_goals,
          key_performance_indicator: other.key_performance_indicator,
          weight: other.weight,
          target: other.target,
          due_date: other.due_date,
          realization: other.realization,
        });
        this.pushData.push({
          user_id: goals[0].item.user_id,
          goal_id: goals[index].item.id,
          midyear_realization: other.realization,
          status: other.status,
        });
      });
    } else if (this.rtype == "new_goals_meeting") {
      let goals = JSON.parse(localStorage.getItem("goals"));
      goals[0].other.map((other, index) => {
        this.datas.push({
          id: other.id,
          user_id: other.user_id,
          strategic_goals: other.strategic_goals,
          key_performance_indicator: other.key_performance_indicator,
          weight: other.weight,
          target: other.target,
          due_date: other.due_date,
          status: other.status,
        });
      });
    } else if (this.rtype == "goals_setting_send_back") {
      let goals = JSON.parse(localStorage.getItem("goals"));
      goals[0].other.map((other, index) => {
        this.datas.push({
          id: other.id,
          user_id: other.user_id,
          strategic_goals: other.strategic_goals,
          key_performance_indicator: other.key_performance_indicator,
          weight: other.weight,
          target: other.target,
          due_date: other.due_date,
          status: other.status,
        });
      });
    }
  },

  methods: {
    submit_goals(e) {
      e.preventDefault();

      if (this.rtype == "new_goals") {
        this.createGoals();
      } else if (this.rtype == "goals_setting_send_back") {
        this.updateGoals();
      } else if (this.rtype == "new_midyear") {
        this.createMidyearGoals();
      } else if (this.rtype == "new_final") {
        this.createAnnual();
      } else if (this.rtype == "final") {
        this.updateAnnual();
      } else {
        this.updateGoals();
      }
    },

    createGoals() {
      this.callApi({
        url: "/goals/create",
        method: "POST",
        data: this.datas,
        success: (res) => {
          this.$router.push("/performance");
          localStorage.setItem("goals", []);
        },
        error: (err) => {
          // console.log(err);
        },
      });
    },

    updateGoals() {
      this.callApi({
        url: `/goals/update/${this.hashid(
          JSON.parse(localStorage.getItem("sw_auth_data")).id
        )}`,
        method: "POST",
        data: this.datas,
        success: (res) => {
          this.$router.push("/performance");
          localStorage.setItem("goals", []);
        },
        error: (err) => {
          // console.log(err);
        },
      });
    },

    createMidyearGoals() {
      this.callApi({
        url: `/midyear_evaluations/create`,
        method: "POST",
        data: this.pushData,
        success: (res) => {
          this.$router.push("/performance");
          localStorage.setItem("goals", []);
        },
        error: (err) => {
          console.log(err);
        },
      });
    },

    createAnnual() {
      if (this.rtype == "new_final") {
        this.endPoint = "/final_evaluations/create";
      }

      this.callApi({
        url: this.endPoint,
        method: "POST",
        data: this.pushData,
        success: (res) => {
          this.$router.push("/performance");
        },
      });
    },

    updateAnnual() {
      let datas = [];
      this.datas.map((item) => {
        datas.push({
          id: item.id,
          final_manager_score: item.final_manager_score,
          final_manager_comment: item.final_manager_comment,
          final_manager_behavior: item.final_manager_behavior,
          final_evaluation_score: item.overAllScore,
          status: "approved",
        });
      });

      this.callApi({
        url: `/final_evaluations/update/${this.hashid(
          JSON.parse(localStorage.getItem("ruid"))
        )}`,
        method: "POST",
        data: datas,
        success: (res) => {
          this.$router.push("/performance");
          localStorage.setItem("goals", []);
        },
        error: (err) => {
          // console.log(err);
        },
      });
    },
  },
};
</script>